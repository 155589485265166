import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro } from '../content/policy'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Tiles from '../components/Tiles'

const Policy = () => {
  const { lang } = useLangContext()

  const { image, children, imageDoc } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/policy.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      imageDoc: file(absolutePath: { regex: "/images/intros/documents.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            width: 480
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      children: allContentfulDocument(sort: { fields: title }) {
        nodes {
          title
          slug
          lang {
            slug
          }
          # image {
          #   gatsbyImageData(width: 480, quality: 100, formats: [JPG])
          # }
        }
      }
    }
  `)

  const links = children.nodes
    .filter((node) => node.lang.slug === lang && node.slug !== "presentation-omida-vls")
    .map((node) => ({
      slug: node.slug,
      name: node.title,
      image: imageDoc.childImageSharp,
    }))

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        imageUrl={image.publicURL}
        url={seo.url}
        noIndex
      />
      <Intro data={intro} image={image.childImageSharp} rounded />
      <Tiles data={links} />
    </Layout>
  )
}

export default Policy
